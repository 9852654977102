import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CardGallery from '@components/CardGallery/CardGallery';
import Card from '@components/Card/Card';
import Title from '@atoms/Title/Title';
import Section from '@atoms/Section/Section';
import { CmsMappedComponent } from '@components/CMSComponents';
import './TheAepsyWay.scss';
import { getTitleGroup } from '@components/CMSComponents/helpers';
import { useLocale } from '@hooks';
import { UserLang } from '@constants/common';
import { useMemo } from 'react';

const TheAepsyWayAnalytics = () => {
    const { currentLanguage } = useLocale();

    const query = useStaticQuery(graphql`
        query {
            aepsyCms {
                theAepsyWayEn: theAepsyWay(locale: "en") {
                    data {
                        attributes {
                            analytics {
                                ... on AEPSYCMS_ComponentCommonOrderedCard {
                                    id
                                    highlightedText
                                    subtitle
                                    cardTitle: title
                                    index
                                    __typename
                                }
                                ... on AEPSYCMS_ComponentAtomsTitleGroup {
                                    id
                                    subtitle
                                    title
                                    __typename
                                }
                            }
                        }
                    }
                }
                theAepsyWayDe: theAepsyWay(locale: "de-CH") {
                    data {
                        attributes {
                            analytics {
                                ... on AEPSYCMS_ComponentCommonOrderedCard {
                                    id
                                    highlightedText
                                    subtitle
                                    cardTitle: title
                                    index
                                    __typename
                                }
                                ... on AEPSYCMS_ComponentAtomsTitleGroup {
                                    id
                                    subtitle
                                    title
                                    __typename
                                }
                            }
                        }
                    }
                }
                theAepsyWayFr: theAepsyWay(locale: "fr-CH") {
                    data {
                        attributes {
                            analytics {
                                ... on AEPSYCMS_ComponentCommonOrderedCard {
                                    id
                                    highlightedText
                                    subtitle
                                    cardTitle: title
                                    index
                                    __typename
                                }
                                ... on AEPSYCMS_ComponentAtomsTitleGroup {
                                    id
                                    subtitle
                                    title
                                    __typename
                                }
                            }
                        }
                    }
                }
                theAepsyWayIt: theAepsyWay(locale: "it") {
                    data {
                        attributes {
                            analytics {
                                ... on AEPSYCMS_ComponentCommonOrderedCard {
                                    id
                                    highlightedText
                                    subtitle
                                    cardTitle: title
                                    index
                                    __typename
                                }
                                ... on AEPSYCMS_ComponentAtomsTitleGroup {
                                    id
                                    subtitle
                                    title
                                    __typename
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const getDataByLang = () => {
        switch (currentLanguage) {
            case UserLang.English:
                return query.aepsyCms.theAepsyWayEn.data;
            case UserLang.French:
                return query.aepsyCms.theAepsyWayFr.data;
            case UserLang.Italian:
                return query.aepsyCms.theAepsyWayIt.data;
            case UserLang.German:
            default:
                return query.aepsyCms.theAepsyWayDe.data;
        }
    };

    const analytics = useMemo(() => {
        if (query) {
            const theAepsyWayData = getDataByLang();
            if (!theAepsyWayData) return [];
            const {
                attributes: { analytics },
            } = theAepsyWayData;
            return analytics;
        }
        return [];
    }, [query]);

    if (analytics.length === 0) return <></>;

    return (
        <>
            {/* ANALYTICS */}
            <Section container="short" spacing="xs">
                <CmsMappedComponent data={getTitleGroup(analytics)} />
            </Section>

            <Section container="large">
                <div className="TheAepsyWay--AnalyticsBlocks">
                    <CardGallery
                        size="3"
                        layout="cascade"
                        variant="mobileInline"
                    >
                        {analytics
                            .filter(
                                (item: any) =>
                                    item.__typename ===
                                    'AEPSYCMS_ComponentCommonOrderedCard'
                            )
                            .map((item: any) => (
                                <Card
                                    format="v"
                                    variant="heroTitle"
                                    visual={
                                        <Title size="xl" font="alt">
                                            {item.highlightedText}
                                        </Title>
                                    }
                                    title={item.cardTitle}
                                    text={item.subtitle}
                                />
                            ))}
                    </CardGallery>
                </div>
            </Section>
        </>
    );
};

export default TheAepsyWayAnalytics;
