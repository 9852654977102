import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Button from '@atoms/Button/Button';
import Module from '@atoms/Module/Module';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import CardWithBackground from '../Card/CardWithBackground';
import IconText from '../IconText/IconText';
import './TherapyPricingShortInfo.scss';

const TherapyPricingShortInfo = ({ imageUrlSharp, mobileImageUrlSharp }) => {
    const { t } = useTranslation();

    return (
        <div className="TherapyPricingShortInfo">
            <div className="TherapyPricingShortInfo--leftContent">
                <CardWithBackground
                    urlSharp={imageUrlSharp}
                    mobileSharpImage={mobileImageUrlSharp}
                    greenBackground
                />
            </div>
            <div className="TherapyPricingShortInfo--rightContent">
                <div className="TherapyPricingShortInfo--card">
                    <Module radius="giant" padding="l">
                        <Section spacingBottom="s">
                            <Text size="m" align="center">
                                {t('for.providers.pricing.card.title')}
                            </Text>
                            <Title tag="h3" size="xl" align="center" font="alt">
                                {t('for.providers.pricing.card.claim')}
                            </Title>
                            <Text size="s" align="center">
                                {t('for.providers.pricing.card.subtitle')}
                            </Text>
                        </Section>

                        <Section container="short">
                            {[
                                t('for.providers.pricing.card.key.point.one'),
                                t('for.providers.pricing.card.key.point.two'),
                                t('for.providers.pricing.card.key.point.three'),
                                t('for.providers.pricing.card.key.point.four'),
                            ].map((item: any) => (
                                <Section spacingBottom="xxs">
                                    <IconText
                                        icon={
                                            <Text
                                                theme="light"
                                                size="l"
                                                noMargin
                                                tag="span"
                                            >
                                                <AiOutlineCheckCircle />
                                            </Text>
                                        }
                                        text={
                                            <Title size="s" noMargin tag="span">
                                                {item}
                                            </Title>
                                        }
                                    />
                                </Section>
                            ))}
                        </Section>
                        <AnchorLink offset={() => 120} href="#provider-signup">
                            <Button
                                variant="naked"
                                iconArrow
                                label={t(
                                    'for.providers.pricing.card.button.to.coverage'
                                )}
                                align="center"
                            />
                        </AnchorLink>
                    </Module>
                </div>
            </div>
        </div>
    );
};

export default TherapyPricingShortInfo;
