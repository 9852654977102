import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import SideSection from '@components/SideSection/SideSection';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '@atoms/Button/Button';
import { SelfEmploymentLink } from '@components/CMSComponents/links';
import Link from '@components/Link';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import useLocale from '@hooks/useLocale';

export default function TherapistFeatures() {
    const { t } = useTranslation();
    const { getAppLinkWithCurrentLang } = useLocale();

    const image_data = useStaticQuery(graphql`
        query {
            feature_01: file(
                relativePath: { eq: "therapist/for-providers-1.jpg" }
            ) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            feature_02: file(
                relativePath: { eq: "therapist/for-providers-2.jpg" }
            ) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            feature_03: file(
                relativePath: { eq: "therapist/for-providers-3.jpg" }
            ) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            feature_04: file(
                relativePath: { eq: "therapist/for-providers-4.jpg" }
            ) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <>
            <SideSection
                title={t(
                    'for.providers.highlighted.features.get.clients.title'
                )}
                text={t('for.providers.highlighted.features.get.clients.text')}
                visual={
                    <Img
                        fluid={image_data.feature_01.childImageSharp.fluid}
                        alt="Passende Klient:innen - Visual"
                    />
                }
                link={
                    <AnchorLink offset={() => 120} href="#provider-signup">
                        <Button
                            variant="inline"
                            theme="highlighted"
                            label={t('join.as.psychologist.cta.label')}
                            iconArrow
                        />
                    </AnchorLink>
                }
                bgContent={false}
            />
            <SideSection
                title={t(
                    'for.providers.highlighted.features.control.of.workload.title'
                )}
                text={t(
                    'for.providers.highlighted.features.control.of.workload.text'
                )}
                visual={
                    <Img
                        fluid={image_data.feature_02.childImageSharp.fluid}
                        alt="Steuerung der Auslastung  - Visual"
                    />
                }
                link={
                    <SelfEmploymentLink
                        buttonProps={{
                            variant: 'inline',
                            iconArrow: true,
                            theme: 'highlighted',
                        }}
                    />
                }
                bgContent={false}
            />
            <SideSection
                title={t('for.providers.highlighted.features.no.admin.title')}
                text={t('for.providers.highlighted.features.no.admin.text')}
                visual={
                    <Img
                        fluid={image_data.feature_03.childImageSharp.fluid}
                        alt="Kein Admin, mehr Zeit  - Visual"
                    />
                }
                link={
                    <a
                        href={getAppLinkWithCurrentLang(
                            `${process.env.GATSBY_AEPSY_APP_URL}/the-swiss-insurance-check`
                        )}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <Button
                            variant="inline"
                            theme="highlighted"
                            label={t('link.to.insurance.checker')}
                            iconArrow
                        />
                    </a>
                }
                bgContent={false}
            />
            <SideSection
                title={t('for.providers.highlighted.features.community.title')}
                text={t('for.providers.highlighted.features.community.text')}
                visual={
                    <Img
                        fluid={image_data.feature_04.childImageSharp.fluid}
                        alt="Nicht mehr Alleine  - Visual"
                    />
                }
                link={
                    <Link to="/providers">
                        <Button
                            variant="inline"
                            theme="highlighted"
                            label={t('button.view.all.psychologists')}
                            iconArrow
                        />
                    </Link>
                }
                bgContent={false}
            />
        </>
    );
}
