import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '@atoms/Section/Section';
import Button from '@atoms/Button/Button';
import Text from '@atoms/Text/Text';
import OrderedCard from '@components/Card/OrderedCard';
import { ModalContext } from '@context/modal';
import MarkdownText from '@atoms/MarkdownText/MarkdownText';
import './SBAPCollaborationBox.scss';

const SBAPCollaborationBox: React.FC<any> = ({
    title,
    subtitle,
    content,
    images,
    buttons,
}) => {
    const { showModal } = useContext(ModalContext);
    const { t } = useTranslation();
    const button = buttons ? buttons[0] : undefined;

    return (
        <Section className="SBAPCollaborationBox">
            <OrderedCard title={title} subtitle={subtitle} images={images}>
                {button && (
                    <Section spacingTop="s">
                        <Button
                            variant="outlined"
                            theme="primary"
                            label={button.label}
                            isMobileFullsize
                            onClick={() => {
                                showModal(
                                    <Section spacingTop="xs">
                                        <Text>
                                            <MarkdownText content={content} />
                                        </Text>
                                    </Section>,
                                    {
                                        title,
                                    }
                                );
                            }}
                        />
                    </Section>
                )}
            </OrderedCard>
        </Section>
    );
};

export default SBAPCollaborationBox;
